<template>
  <div class="content">
    <el-form inline
             :model="search"
             class="tb-form">
      <!-- <el-form-item label="品牌名称：">
        <el-select v-model="search.brandid" clearable>
          <el-option :label="item.brandName" :value="item.id" v-for="item in brandOptions" :key="item.id"></el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item label="买家手机号：">
        <el-input v-model="search.phone"></el-input>
      </el-form-item>
      <el-form-item label="买家昵称：">
        <el-input v-model="search.nickname"></el-input>
      </el-form-item>
      <el-form-item label="订单编号：">
        <el-input v-model="search.ordersn"></el-input>
      </el-form-item>
      <el-form-item label="下单时间：">
        <el-date-picker v-model="search.date"
                        type="daterange"
                        value-format="yyyy-MM-dd"
                        format="yyyy-MM-dd"
                        :clearable="false"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="退款状态：">
        <el-select v-model="search.refund_status"
                   clearable>
          <el-option label="无退款"
                     :value="0"></el-option>
          <el-option label="部分退款"
                     :value="1"></el-option>
          <el-option label="全部退款"
                     :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary"
                   @click="onSearch">搜索</el-button>
      </el-form-item>
    </el-form>
    <div class="add">
      <el-button-group>
        <el-button v-for="item in statusOptions"
                   :key="item.status"
                   :type="search.status === item.status ? 'primary' : ''"
                   @click="changeStatus(item.status)">
          {{item.name}}
        </el-button>
      </el-button-group>
    </div>
    <div class="tablebox">
      <el-table :data="tableData"
                class="tb-table"
                @row-dblclick="toDetail">
        <el-table-column label="订单号"
                         min-width="180">
          <template slot-scope="scope">
            <el-popover width="200"
                        placement="top"
                        trigger="hover"
                        :content="scope.row.ordersn">
              <div slot="reference"
                   class="ellipsis-one">{{scope.row.ordersn}}</div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="date"
                         label="下单时间"
                         min-width="100" />
        <!-- <el-table-column label="关联品牌" min-width="100">
          <template slot-scope="scope">
            <el-popover
              width="100"
              placement="top"
              trigger="hover"
              :content="scope.row.brand_name"
            >
              <div slot="reference" class="ellipsis-one">{{scope.row.brand_name}}</div>
            </el-popover>
          </template>
        </el-table-column> -->
        <el-table-column label="商品">
          <template slot-scope="scope">
            {{scope.row.items.length + "个商品"}}<span style="margin-left: 8px;"
                  class="edit"
                  @click="showProductDlg(scope.row)">查看</span>
          </template>
        </el-table-column>
        <el-table-column prop="price"
                         label="订单总价" />
        <!-- <el-table-column prop="return_amount" label="可获佣金"/> -->
        <el-table-column prop="contactor"
                         label="收货人" />
        <el-table-column label="收货地址"
                         min-width="300">
          <template slot-scope="scope">
            <el-popover width="320"
                        placement="top"
                        trigger="hover"
                        :content="scope.row.address">
              <div slot="reference"
                   class="ellipsis-one">{{scope.row.address}}</div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="mobile"
                         label="手机号"
                         min-width="120" />
        <el-table-column prop="statusname"
                         label="状态" />
        <el-table-column prop="nums"
                         label="商品总量" />
        <el-table-column prop="business_remark"
                         label="商家备注"
                         show-overflow-tooltip />
        <el-table-column label="操作">
          <router-link slot-scope="scope"
                       :to="`/order/detail?id=${scope.row.id}`"
                       class="edit">编辑</router-link>
        </el-table-column>
      </el-table>
      <el-pagination background
                     class="tb-pagination"
                     @size-change="sizeChange"
                     layout="prev, pager, next, sizes"
                     :page-sizes="[10,20,50,100]"
                     :page-size="page.size"
                     :total="page.total"
                     @current-change="pageChange" />
    </div>
    <el-dialog title="商品列表"
               :visible.sync="isProductDlg"
               custom-class="product-dlg">
      <el-table :data="orderItems"
                style="width: 100%; border: 1px solid #e9e9e9"
                class="tb-table">
        <el-table-column label="商品名称"
                         prop="productName"></el-table-column>
        <el-table-column label="规格"
                         prop="skuName"
                         show-overflow-tooltip></el-table-column>
        <el-table-column label="数量"
                         prop="num"></el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'OrderList',
  data () {
    return {
      search: {
        // brandid: 71,
        phone: '',
        nickname: '',
        ordersn: '',
        // date: [this.$dayjs().subtract(30, 'day').format('YYYY-MM-DD'), this.$dayjs().format('YYYY-MM-DD')],
        date: ['', ''],
        status: 1,
        refund_status: ''
      },
      statusOptions: [
        { status: -1, name: '所有订单' },
        { status: 0, name: '待付款' },
        { status: 1, name: '待发货' },
        { status: 2, name: '已发货' },
        { status: 3, name: '已完成' },
        { status: 6, name: '关闭' }
      ],
      tableData: [],
      page: {
        no: 0,
        size: 10,
        total: 0
      },
      isProductDlg: false
    }
  },
  computed: mapState([
    'brandOptions'
  ]),
  mounted () {
    let path = this.$route.path
    if (path.indexOf('/') === 0) path = path.substring(1)
    const currentPage = {
      link: path,
      name: '订单列表'
    }
    this.$store.commit('changeRoute', currentPage)
    if (this.$route.params.status) this.search.status = this.$route.params.status * 1
    this.getList()
  },
  methods: {
    async getList () {
      let { no, size } = this.page
      let { brandid, status, phone, nickname, date, ordersn, refund_status } = this.search
      const params = {
        brand_id: brandid,
        contactor: nickname,
        status,
        mobile: phone,
        page_size: size,
        page_no: no,
        // start_time: date[0] + ' 00:00:00',
        // end_time: date[1] + ' 23:59:59',
        ordersn,
        refund_status
      }
      if (date[0]) {
        params['start_time'] = date[0] + ' 00:00:00'
      }
      if (date[1]) {
        params['end_time'] = date[1] + ' 23:59:59'
      }
      const url = 'admin/order/list'
      const data = await this.$https.get(url, { params })
      if (!data) return
      const list = data.list.filter(item => item.status !== -1)
      const statusname = ['待付款', '已付款', '已发货', '已完成', '部分退款', '全部退款', '关闭']
      list.forEach(item => {
        item.date = this.$dayjs(item.created_at).format('YYYY-MM-DD hh:mm')
        item.price = this.$np.divide(item.original_price, 100)
        item.return_amount = item.return_amount ? this.$np.divide(item.return_amount, 100) : '--'
        item.nums = item.items.length
        item.statusname = statusname[item.status]
      })
      this.page.total = data.total_count
      this.tableData = list
    },
    onSearch () {
      this.page.no = 0
      this.getList()
    },
    changeStatus (type) {
      this.search.status = type
      this.getList()
    },
    sizeChange (val) {
      this.page.no = 0
      this.page.size = val
      this.getList()
    },
    pageChange (no) {
      this.page.no = no - 1
      this.getList()
    },
    toDetail (row) {
      this.$router.push(`/order/detail?id=${row.id}`)
    },
    showProductDlg (row) {
      this.orderItems = row.items
      this.isProductDlg = true
    }
  }
}
</script>

<style lang="scss">
.product-dlg .el-dialog__header {
  border-bottom: 1px solid #e9e9e9;
}
</style>
